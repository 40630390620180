&-full {
  &-modal {
    &-img {
      img {
        display: block;
        width: 100%;
      }
      &.img-success{
        max-width:120px;
        margin: 0 auto;
      }
    }

    &-inner {
      background-color: transparent; 
    }

    &-body {
      border-radius: $border-radius*4;
      // @include gradient-y-three-colors($start-color: rgba($black, 0), $mid-color: rgba($gray-800, .9), $color-stop: 30%, $end-color: rgba($gray-800, .9));
      @include gradient-radial($inner-color: $yellow, $outer-color: $cyan);
      padding: $font-size-base * 3.5;

      .close {
        position: absolute;
        right: $font-size-base *2 ;
        top: 0;
        border: 0;
        box-shadow: none;
        background: transparent escape-svg($btn-close-white) center / $btn-close-width auto no-repeat;
        background-color: rgba($secondary, .8);
        color: $white;
        width: $font-size-base*2.6;
        height: $font-size-base*2.6;
        text-align: center;

        &:hover {
          background-color: $primary;
        }
      }
    }

    &-form {
      .heading {
        margin-bottom: $font-size-base * 2;

        .text {
          display: inline-block;
          background-image: url("../images/line.svg");
          background-position: 50% 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-attachment: scroll;
          -webkit-transition-property: all;
          transition-property: all;
        }
      }
    }
  }

  &-itinerary {
    display: block;
    position: relative;
    @include transition(all 0.3s ease-in-out);

    margin-bottom: $font-size-base*2;

@include media-breakpoint-up(lg) { margin-bottom: $font-size-base*4; }
@include media-breakpoint-up(xl) { margin-bottom: $font-size-base*4; }
@include media-breakpoint-up(xxl){ margin-bottom: $font-size-base*4; }

    .package-itinerary {
      border: 2px solid $border-color;
      border-radius: $border-radius;
      padding: $font-size-base; padding-top: $font-size-base * 1.25;
      position: relative;

      @include media-breakpoint-up(lg) {padding: $font-size-base * 2; padding-top: $font-size-base * 2.5;}
      @include media-breakpoint-up(xl) {padding: $font-size-base * 2; padding-top: $font-size-base * 2.5;}
      @include media-breakpoint-up(xxl){padding: $font-size-base * 2; padding-top: $font-size-base * 2.5;}

      .tour-header {
        position: absolute;
        top: -$font-size-base;
        left: $font-size-base * 2;
        background-color: $primary;
        color: $white;
        z-index: 2;
        padding: $font-size-base*.2 $font-size-base*.9;
        border-radius: $border-radius*10;

        @include media-breakpoint-up(lg) {top: -$font-size-base * 1.5; padding: $font-size-base*.5 $font-size-base*2; }
        @include media-breakpoint-up(xl) {top: -$font-size-base * 1.5; padding: $font-size-base*.5 $font-size-base*2; }
        @include media-breakpoint-up(xxl){top: -$font-size-base * 1.5; padding: $font-size-base*.5 $font-size-base*2; }
      }
    }

    &-quote-form {
      border: 2px solid $border-color;
      border-radius: $border-radius;
      padding: $font-size-base * 2;

      margin-top: $font-size-base * 2;

@include media-breakpoint-up(lg) { margin-top: 0; }
@include media-breakpoint-up(xl) { margin-top: 0; }
@include media-breakpoint-up(xxl){ margin-top: 0; }

      .heading {
        margin-bottom: $font-size-base * 2;

        .text {
          display: inline-block;
          background-image: url("../images/line.svg");
          background-position: 50% 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-attachment: scroll;
          -webkit-transition-property: all;
          transition-property: all;
        }
      }
    }

    &-row {
      padding: $font-size-base * 2.5 0;

@include media-breakpoint-up(lg) {padding: $font-size-base * 3.5 0;}
@include media-breakpoint-up(xl) {padding: $font-size-base * 3.5 0;}
@include media-breakpoint-up(xxl){padding: $font-size-base * 3.5 0;}

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
        }
      }

      .on-request{ 
        display: block;
        @include media-breakpoint-up(lg) {display: -webkit-box; display: -ms-flexbox; display: flex;}
        @include media-breakpoint-up(xl) {display: -webkit-box; display: -ms-flexbox; display: flex;}
        @include media-breakpoint-up(xxl){display: -webkit-box; display: -ms-flexbox; display: flex;}
                
      }

      .tour {
        &-title {
          margin-bottom: $font-size-base * 1.5;

          .duration {
            font-weight: 600;
            color: $gray-600;
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }
        }

        &-duration {
          margin-bottom: $font-size-base;

          .title {
            font-size: 120%;
            font-weight: 600;
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }
        }


        &-destinations {
          margin-bottom: $font-size-base*1.3;

          .title {
            font-size: 120%;
            font-weight: 600;
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }

          .list {
            li {
              color: $primary;
              font-weight: 600;
              position: relative;

              &::after {
                margin-left: $font-size-base*.6;
                margin-right: $font-size-base*.6;
                content: '';
                display: inline-flex;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('../images/arrow-right-solid.svg');
                background-size: $font-size-base $font-size-base;
                width: $font-size-base;
                height: $font-size-base*.8;
                color: $primary;
                text-align: center;
              }

              &:last-child {
                &::after {
                  display: none;
                }
              }
            }
          }
        }

        &-on-request {
          margin-bottom: $font-size-base*1.4;

          .title {
            font-size: 120%;
            font-weight: 600;
            margin-bottom: $font-size-base*.5;
          }

          .ico-svg {
            display: block;
            margin-bottom: $font-size-base*.5;

            img,
            .svg {
              margin: 0 auto;
              width: $font-size-base*1.8;
              display: block;
            }
          }

          .list {
            li {
              text-align: center;
              padding: 0 $font-size-base*1.2;
              font-weight: 600;
              font-size: 80%;
              color: $primary;
              position: relative;

              &::after {
                position: absolute;
                top: $font-size-base*.1;
                bottom: $font-size-base*.4;
                width: 2px;
                background-color: darken($border-color, 20%);
                right: 0;
                content: "";
              }

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;

                &::after {
                  display: none;
                }
              }
            }
          }

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }
        }

        &-link {
          margin-right: $font-size-base;

          .content {
            margin: 0;
            @include text-ellipsis();
            white-space: nowrap;
            -webkit-box-flex: 1;
            flex-grow: 1;
            flex: 1;
          }
        }

        &-rout {
          &-list {
            margin-top: $font-size-base * 2;
            padding-top: $font-size-base * 2;
            border-top: 1px solid $border-color;
            display: block;

            &-title {
              margin-bottom: $font-size-base;
              color: $primary;
              font-weight: 800;
              font-size: 140%;
              line-height: 1.2;

              .heading {
                font-size: 80%;
              }
            }

            &-content {
              position: relative;
              display: block;

              // padding-left: $font-size-base * 2;
              &::after {
                position: absolute;
                top: $font-size-base * .2;
                height: 70%;
                width: 4px;
                border-radius: $border-radius;
                background-color: darken($border-color, 20%);
                left: -$font-size-base * 2.2;
                content: "";
              }

              ul.pick-list {
                margin: 0;
                padding: 0;

                li {
                  margin: $font-size-base*.8 auto;
                  padding-left: $font-size-base*1.6;
                  position: relative;

                  &:after {
                    position: absolute;
                    width: $font-size-base*.6;
                    height: $font-size-base *.6;
                    background-color: $secondary;
                    left: 0;
                    top: $font-size-base *.35;
                    content: "";
                    z-index: 2;
                  }

                  &:last-child {
                    margin: 0;
                  }
                }
              }
            }

            &-info {
              display: block;

              li {
                margin-bottom: $font-size-base * 2;

                &:last-child {
                  margin: 0;
                }
              }
            }

            &-info {
              display: block;

              li {
                margin-bottom: $font-size-base * 2;

                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }

  }
}

.close-white {
  display: block;

}
