&-destination {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-lg *3;

  .card-icon {
    cursor: pointer;

    img {
      @include transition(all 0.3s ease);
      display: block;
      width: 100%;
      filter: grayscale(1);
    }
  }

  .title-text {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    // 
    line-height: 1;
    @include gradient-y-three-colors($start-color: rgba($black, 0), $mid-color: rgba($gray-800, .9), $color-stop: 50%, $end-color: rgba($gray-800, .9));
    padding: $font-size-base $font-size-base;

    .title {
      color: $white;
      font-size: 20px;
      margin-bottom: $font-size-base * 1.2;
      
    }
    .call-now {
      
      .bold {display: block;}
    }
    .days {
      font-weight: 700;
      font-size: 80%;
      margin-bottom: $font-size-base * .3;
    }

    .text {
      margin: 0;
      @include text-ellipsis();
      white-space: nowrap;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex: 1;
    }


    .point {
      color: $white;
      font-size: 80%;

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        list-style: none;
        position: relative;
        margin-bottom: $font-size-base*.1;
        padding-left: $font-size-base;

        &:after {
          content: "";
          position: absolute;
          background-color: $primary;
          top: $font-size-base*.1;
          left: 0;
          width: $font-size-base*.5 ;
          height: $font-size-base*.5 ;
        }
      }

    }
  }
  
  .book-now {
    margin-top: $font-size-base * 1.4;
    color: $white;
    font-size: 20px;

    .price-in {
      padding-left: $font-size-base*.6;
    }

    .icons {
      font-size: 150%;
    }

    .rstag {
      font-size: 80%;

    }

    .ammount {
      font-size: 120%;
      font-weight: 600;
    }

    .person {
      font-size: 60%;

    } 

    &-tag{
      position: absolute;
      top: $font-size-base*1.5;
      right: 0;
      z-index: 3;
      .price{
        background: rgba($black, .4);
        color: $white;
        padding: $font-size-base*.5 $font-size-base;
        font-weight: 600;
      }
    }
  }

  &:hover {
    .card-icon {
      img {
        @include transition(all 0.3s ease);
        filter: grayscale(0);
      }
    }
  }

  &-slider{ 
    .swiper-slide-active,.swiper-slide:hover{
      img {
        @include transition(all 0.3s ease);
        display: block;
        width: 100%;
        filter: grayscale(0);
      }
    }
  }
}
